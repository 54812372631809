import React from "react";
import { useState, useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { useParams, Link } from "react-router-dom";
import 'semantic-ui-css/semantic.min.css';
import { FormField, Button, Form, Image, Menu, Icon, Input, Popup } from "semantic-ui-react";
import axios from "axios";
import TopMenu from "../TopMenu";
import FooterBar from "../FooterBar";

const Artist = () => {
  const childRef = useRef(null);
  const [ selectItem, setSelectItem ] = useState(true);
  const selectTrigger = () => {
    setSelectItem(!selectItem);
    setFirst(false)
  }
  const [first, setFirst] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const isBigScreen = useMediaQuery({maxWidth: 990})
  const params = useParams();
  const [ allResult, setAllResult ] = useState({"albums":[],"artist":[],"tracks":[]});
  const [ toggleSearch, setToggleSearch ] = useState(false);

  useEffect(()=>{
    if (first === false){
      axios.get(`/searchArtistInfo?artist_id=${params.artist}`)
      .then((response) => {
        console.log(response.data);
        setToggleSearch(!toggleSearch);
        setAllResult(response.data);
      }).catch((e)=>{
        console.log(e);
      })
    } else {
      console.log("pass");
    }
    setFirst(true);
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    // 창 크기가 변경될 때마다 핸들러 함수 호출
    window.addEventListener('resize', handleResize);
    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  },[windowWidth, selectItem])

  return (
    <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
    <TopMenu ref={childRef} parentFunction={selectTrigger}/>
    <div style={{display:"flex", flexDirection:"column", justifyContent:"center",
                width:isBigScreen?windowWidth-10:960, padding:20}}>


      <Image style={{width:300}} src={allResult.artist[1]}/>
      <div style={{fontSize:20, padding:10}}>{allResult.artist[0]}</div>

      <div style={{padding:10}}>
        <h2>Top Tracks</h2>
      </div>
      {allResult.tracks.map((item, idx)=>
        <div style={{display:"flex", width:400, padding:5, marginBottom:5}}>
          <div style={{display:"flex", alignItems:"center", width:400,}}>
          <div style={{paddingRight:10}}>
            <Image style={{height:70}} src={item[4]}/>
          </div>
            <div>
              <div style={{fontSize:13}}>
                  <div>{item[0]}</div>
              </div>
              <div style={{fontSize:20}}>
                <Link to={`/song/${item[0]}/${item[1]}`}>
                {item[1]}
                </Link>
              </div>
              <div style={{fontSize:12}}>
                {item[2]} / {item[3]}
              </div>
            </div>

          </div>
        </div>
      )}

      <div style={{padding:10}}>
        <h2>Albums</h2>
      </div>
      <div>
        {allResult.albums.map((item, idx)=>
        <div style={{display:"flex", width:400, padding:5, marginBottom:5}}>
        <div style={{display:"flex", alignItems:"center", width:400,}}>
        <div style={{paddingRight:10}}>
          <Image style={{height:70}} src={item[3]}/>
        </div>
          <div>
            <div style={{fontSize:20}}>
              {item[1]}
            </div>
            <div style={{fontSize:12}}>
              {item[2]}
            </div>
          </div>

        </div>
      </div>
        )}
      </div>


    </div>
  <FooterBar />
  </div>
  )
}

export default Artist;

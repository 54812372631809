import React from "react";

const FooterBar = () => {
    return (
    <div style={{display:"flex", justifyContent:"center", width:960, minHeight:10,
                padding:20}}>
      <h5>J-POP 가사나라</h5>
    </div>
    )
  }

export default FooterBar;
import React from "react";
import { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import 'semantic-ui-css/semantic.min.css';
import { FormField, Button, Form, Image, Loader, Icon, Input } from "semantic-ui-react";
import axios from "axios";
import Playlists from "./Playlists";
import Artists from "./Artists";
import Menu3 from "./Menu3";
import Song from "./Song";
import Main from "./Main";
import Artist from "./Artist";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />}></Route>
          <Route path="/playlists" element={<Playlists />}></Route>
          <Route path="/artists" element={<Artists />}></Route>
          <Route path="/song/:artist" Component={Menu3}></Route>
          <Route path="/song/:artist/:track" Component={Song}></Route>
          <Route path="/artist/:artist" Component={Artist}></Route>
          <Route path="/home" element={<Home />}></Route>
          <Route path="/menu3" element={<Menu3 />}></Route>
          <Route path="/side" element={<Side />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

const Home = () => {
  return (
    <div>
      <div>Home</div>
    </div>
  )
}

const Side = () => {
  return (
    <div>Side</div>
  )
}
export default App;

import React from "react";
import { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import 'semantic-ui-css/semantic.min.css';
import { Image, Dimmer, Loader , Segment, Popup, Icon} from "semantic-ui-react";
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from "react-responsive-carousel";
import axios from "axios";
import TopMenu from "../TopMenu";
import FooterBar from "../FooterBar";

const Artists = () => {

  const [first, setFirst] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const isBigScreen = useMediaQuery({maxWidth: 990})
  const [ loadingCircle, setLoadingCircle ] = useState(false);

  const [ allResult, setAllResult ] = useState({
    "artist":[],"pic":[],"followers":[],"popularity":[],"ids":[]
  })

  useEffect(() => {

    if (first === false) {
      setLoadingCircle(true);
      axios.get(`/artistlists`)
      .then((response)=>{
        setLoadingCircle(false);
        // console.log(response.data);
        setAllResult(response.data);
      })
      .catch( e => {
        console.log(e);
      })
    } else {
    }
    setFirst(true);

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    // 창 크기가 변경될 때마다 핸들러 함수 호출
    window.addEventListener('resize', handleResize);
    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [windowWidth]);

  return (
    <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
    <TopMenu />

    <Segment style={{borderWidth:1, border:"1px solid #85B3d1",
                    width:isBigScreen?windowWidth-4:960, minHeight:80}}>
      <Dimmer active={loadingCircle?true:false} inverted={true}>
        <Loader style={{top:40}} inverted>Loading</Loader>
      </Dimmer>
      <div style={{display:"flex", flexWrap:"wrap", maxWidth:990, justifyContent:"center"}}>
        {allResult.artist.map((item, idx)=>
            <Link to={`/artist/${allResult.ids[idx]}`}>
              <Popup style={{}}
                  content={allResult.artist[idx]}
                  trigger={<Image style={{width:180}}
                  src={allResult.pic[idx]}/>}/>
            </Link>
        )}
      </div>
    </Segment>

    <FooterBar />
    </div>
  )
}

export default Artists;

import React from "react";
import { useState, useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { useParams, Link } from "react-router-dom";
import 'semantic-ui-css/semantic.min.css';
import { Image, Icon, Dimmer, Loader , Segment} from "semantic-ui-react";
import axios from "axios";
import FooterBar from "../FooterBar";
import TopMenu from "../TopMenu";

const Song = () => {
  const childRef = useRef(null);
  const [ selectItem, setSelectItem ] = useState(true);
  const selectTrigger = () => {
    setSelectItem(!selectItem);
    setFirst(false)
  }
  const [first, setFirst] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const isBigScreen = useMediaQuery({maxWidth: 980})
  const params = useParams();
  const noResult = {
    "html_address":"",
    "artist":"",
    "song":"",
    "lyrics":"[]",
    "artist_furigana":"",
    "song_furigana":"",
    "lyrics_furigana":"[]",
    "artist_han":"",
    "song_han":"",
    "lyrics_han":"[]",
    "artist_trans":"",
    "song_trans":"",
    "lyrics_trans":"[]",
    "song_jacket":"",
    "album_info":"",
    "release_date":"",
    "spotify_url":"",
    "youtube_id" :"",
    "artist_sec":"",
    "song_sec":"",
  }
  const [ statusBar, setStatusBar ] = useState("status bar");
  const [ loadingCircle, setLoadingCircle ] = useState(false);

  const [ allResult, setAllResult ] = useState(noResult);
  const [ toggleSearch, setToggleSearch ] = useState(false);
  const [ toggleLyrics, setToggleLyrics ] = useState(true);
  const [ toggleFuriLyrics, setToggleFuriLyrics ] = useState(true);
  const [ toggleHanLyrics, setToggleHanLyrics ] = useState(true);
  const [ toggleTransLyrics, setToggleTransLyrics ] = useState(true);

  const toggleLyricsFunction = lyric => {
    switch (lyric) {
      case "l":
        setToggleLyrics(!toggleLyrics);
        break
      case "f":
        setToggleFuriLyrics(!toggleFuriLyrics);
        break
      case "h":
        setToggleHanLyrics(!toggleHanLyrics);
        break
      case "t":
        setToggleTransLyrics(!toggleTransLyrics);
        break
      default:
        break
    }
  }

  useEffect(()=>{
    setStatusBar("");
    if (first === false) {
      setLoadingCircle(true);
      axios.get(`/searchlyrics_server?artist=${params.artist}&song=${params.track}`)
      .then((response) => {
        if (response.data === "no") {
          axios.get(`/searchlyrics_utanet?artist=${params.artist}&song=${params.track}`)
          .then((response2) => {
            if (response2.data === "no"){
              setLoadingCircle(true);
              axios.get(`/searchlyrics_jlyric?artist=${params.artist}&song=${params.track}`)
              .then((response3) => {
                if (response3.data === "no"){
                  setLoadingCircle(false);
                  setStatusBar("검색 실패 😭");
                } else {
                  setLoadingCircle(false);
                  setToggleSearch(true);
                  setAllResult(response3.data);
                }
              })
              .catch((e)=>{
                setLoadingCircle(false);
                setStatusBar("페이지 새로 고침")
              })
            } else {
              setLoadingCircle(false);
              setStatusBar("");
              setToggleSearch(true);
              setAllResult(response2.data);
            }
          })
          .catch((e)=>{
            setLoadingCircle(false);
            setStatusBar("페이지 새로 고침")
          })
        } else {
          console.log(response.data);
          setLoadingCircle(false);
          setStatusBar("");
          setToggleSearch(true);
          setAllResult(response.data);
        }
      })
      .catch((e)=>{
        setLoadingCircle(false);
        setStatusBar("페이지 새로 고침")
      })
    } else {
      console.log("pass");
    }
    setFirst(true);

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    // 창 크기가 변경될 때마다 핸들러 함수 호출
    window.addEventListener('resize', handleResize);
    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  },[windowWidth, selectItem])

  return (
    <div style={{display:"flex", flexDirection:"column",
                alignItems:"center",}}>
    <TopMenu ref={childRef} parentFunction={selectTrigger}/>
    <div style={{alignSelf:"center"}}>
          {statusBar}
    </div>
    <Segment style={{borderWidth:1, border:"1px solid #85B3d1",
                    width:isBigScreen?windowWidth-4:960, minHeight:80}}>
      <Dimmer active={loadingCircle?true:false} inverted={true}>
        <Loader style={{top:40}} inverted>Loading</Loader>
      </Dimmer>

    <div style={{display:toggleSearch?"flex":"none", flexDirection:"column",
                justifyContent:"center", alignItems:isBigScreen?"baseline":"center", padding:20,
                width:isBigScreen?windowWidth-50:960,}}>
      <Image style={{width:300}} src={allResult.song_jacket}/>
      <div style={{fontSize:16, paddingLeft:5, paddingBottom:10}}>
        {allResult.album_info} ({allResult.release_date})
      </div>
      <div style={{padding:10, fontSize:isBigScreen?14:20}}>
        <Link style={{paddingRight:5, color:toggleLyrics?"#5CC09D":"#888888"}}
                      onClick={()=>toggleLyricsFunction("l")}>
          가사
        </Link>
        /
        <Link style={{paddingInline:5, color:toggleFuriLyrics?"#5CC09D":"#888888"}}
                      onClick={()=>toggleLyricsFunction("f")}>
          후리가나
        </Link>
        /
        <Link style={{paddingInline:5, color:toggleHanLyrics?"#5CC09D":"#888888"}}
                      onClick={()=>toggleLyricsFunction("h")}>
          독음
        </Link>
        /
        <Link style={{paddingInline:5, color:toggleTransLyrics?"#5CC09D":"#888888"}}
                      onClick={()=>toggleLyricsFunction("t")}>
          번역
        </Link>
      </div>
      <div style={{display:"flex", flexDirection:"row", padding:10,}}>
        <Link style={{paddingInline:5, color:"#CD201F"}}
            onClick={()=>{window.open(`https://www.youtube.com/watch?v=${allResult.youtube_id}`,
                          '_blank')}}>
          <Icon name='youtube' size='big' />
        </Link>
        <Link style={{paddingInline:5, color:"#1DB954"}}
            onClick={()=>{window.open(allResult.spotify_url, '_blank')}}>
          <Icon name='spotify' size='big' />
        </Link>
      </div>
      <div style={{display:"flex", flexDirection:"column",
                  padding:10, fontSize:isBigScreen?20:30, paddingTop:20}}>
        <div style={{padding:isBigScreen?5:10, alignSelf: isBigScreen?"baseline":"center"}}>
          {allResult.song}
        </div>
        <div style={{padding:isBigScreen?5:10, alignSelf: isBigScreen?"baseline":"center"}}>
          {allResult.song_furigana}
        </div>
        <div style={{padding:isBigScreen?5:10, alignSelf: isBigScreen?"baseline":"center"}}>
          {allResult.song_han}
        </div>
        <div style={{padding:isBigScreen?5:10, alignSelf: isBigScreen?"baseline":"center"}}>
          {allResult.song_trans}
        </div>
      </div>
      <div style={{display:"flex", flexDirection:"column",
                  padding:10, fontSize:isBigScreen?20:30, paddingTop:20}}>
        <div style={{padding:isBigScreen?5:10, alignSelf: isBigScreen?"baseline":"center"}}>
          {allResult.artist}
        </div>
        <div style={{padding:isBigScreen?5:10, alignSelf: isBigScreen?"baseline":"center"}}>
          {allResult.artist_furigana}
        </div>
        <div style={{padding:isBigScreen?5:10, alignSelf: isBigScreen?"baseline":"center"}}>
          {allResult.artist_han}
        </div>
        <div style={{padding:isBigScreen?5:10, alignSelf: isBigScreen?"baseline":"center"}}>
          {allResult.artist_trans}
        </div>
      </div>
      {JSON.parse(allResult.lyrics).map((item, idx) =>
        <div key={"main_div_"+idx}
             style={{display:"flex", flexDirection:"column",
                    padding:10, fontSize:isBigScreen?15:18, }}>
          <div style={{display:toggleLyrics?"block":"none",
                      alignSelf: isBigScreen?"baseline":"center",
                      paddingTop:isBigScreen?2:10}}>
            {JSON.parse(allResult.lyrics)[idx]}
          </div>
          <div style={{display:toggleFuriLyrics?"block":"none",
                      alignSelf: isBigScreen?"baseline":"center",
                      paddingTop:isBigScreen?2:10}}>
            {JSON.parse(allResult.lyrics_furigana)[idx]}
          </div>
          <div style={{display:toggleHanLyrics?"block":"none",
                      alignSelf: isBigScreen?"baseline":"center",
                      paddingTop:isBigScreen?2:10}}>
            {JSON.parse(allResult.lyrics_han)[idx]}
          </div>
          <div style={{display:toggleTransLyrics?"block":"none",
                      alignSelf: isBigScreen?"baseline":"center",
                      paddingTop:isBigScreen?2:10}}>
            {JSON.parse(allResult.lyrics_trans)[idx]}
          </div>
        </div>
      )}
    </div>
  </Segment>
  <FooterBar />
  </div>
  )
}

export default Song;

import React from "react";
import { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import 'semantic-ui-css/semantic.min.css';
import { Image, Dimmer, Loader , Segment, Popup} from "semantic-ui-react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import axios from "axios";
import TopMenu from "../TopMenu";
import FooterBar from "../FooterBar";

const Playlists = () => {
  const [first, setFirst] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const isBigScreen = useMediaQuery({maxWidth: 990})
  const [ loadingCircle, setLoadingCircle ] = useState(false);

  const [ playlists, setPlaylists ] = useState(
    {"name":[],
    "img":[],
    "description":[],
    "id":[]}
  )
  const [ songList, setSongList ] = useState(
    {"album_name":[],
    "artist_list":[],
    "jacket_image":[],
    "release_date":[],
    "track_name":[]}
  )
  const getSongs = (id, url) => {
    setOnImage(url);
    axios.get(`/songlist?playlist_id=${id}`)
    .then((response)=>{
      // console.log(response.data);
      setSongList(response.data);
    })
  }

  const [ onImage, setOnImage ] = useState("")

  useEffect(()=>{
    if (first === false) {
      setLoadingCircle(true);
      axios.get(`/playlistss`)
      .then((response)=>{
        setLoadingCircle(false);
        setPlaylists(response.data);
      })
      .catch( e => {
        console.log(e);
      })
    } else {
      console.log("")
    }
    setFirst(true);

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    }
    // 창 크기가 변경될 때마다 핸들러 함수 호출
    window.addEventListener('resize', handleResize);
    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  },[windowWidth])

  return (
    <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>

    <TopMenu />

    <Segment style={{borderWidth:1, border:"1px solid #85B3d1",
                    width:isBigScreen?windowWidth-4:960, minHeight:80}}>
      <Dimmer active={loadingCircle?true:false} inverted={true}>
        <Loader style={{top:40}} inverted>Loading</Loader>
      </Dimmer>
    <div style={{display:"flex", justifyContent:"center", padding:10}}>
      <Image style={{width:350}} src={onImage} />
    </div>
    <div style={{display:"flex", flexWrap:"wrap", maxWidth:990, justifyContent:"center"}}>
      {playlists.img.map((item, idx)=>
      <div onClick={()=>getSongs(playlists.id[idx], playlists.img[idx])}>
        <Popup style={{}}
        content={playlists.description[idx]}
        trigger={<Image style={{width:110}} src={item}/>}/>
      </div>
      )}
    </div>
    <div style={{display:"flex", justifyContent:"center", paddingTop:30,}}>
      <h2>Tracks</h2>
    </div>
    <div style={{display:"flex", flexDirection:"column", justifyContent:"center",
                alignItems:"center", padding:20}}>
      {songList.track_name.map((item, idx)=>
        <div style={{display:"flex", width:400, padding:5, marginBottom:5}}>
          <div style={{display:"flex", alignItems:"center", width:400,}}>
          <div style={{paddingRight:10}}>
            <Image style={{height:70}} src={songList.jacket_image[idx]}/>
          </div>
            <div>
              <div style={{fontSize:13}}>
                {songList.artist_list[idx].map((item, idx)=>
                  <div>{item}</div>
                )}
              </div>
              <div style={{fontSize:20}}>
                <Link to={`/song/${songList.artist_list[idx][0]}/${item}`}>
                {item}
                </Link>
              </div>
              <div style={{fontSize:12}}>
                {songList.album_name[idx]} / {songList.release_date[idx]}
              </div>
            </div>

          </div>
        </div>
      )}
    </div>
    </Segment>

    <FooterBar />
    </div>
  )
}

export default Playlists;

import React from "react";
import { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";
import 'semantic-ui-css/semantic.min.css';
import { FormField, Button, Form, Image, Menu, Icon } from "semantic-ui-react";
import axios from "axios";

const Menu3 = () => {
  const params = useParams()

  useEffect(()=>{
    console.log("menu3")
    axios.get(`/artistsearch?artist=${params.artist}`)
    .then((response)=>{
      console.log(response.data);
    })
    .catch( e => {
      console.log(e);
    })
  },[])

  return (
    <div>
      {params.artist}<br />
    </div>
  )
}

export default Menu3;
